import request from '@/plugins/axios';
import { base64ToBlob, clearCookie } from '@/util';
import compressor from '@/util/compressorJs';
import { showConfirmDialog } from 'vant';

/**
 * 用户登录
 */
export function getTokenService(data = {}) {
  clearCookie();
  return request({
    url: `/auth/oauth/token`,
    method: 'post',
    headers: {
      notNeedToken: true, // 是否不需要token。默认需要。
      getAllResponse: true, // 获取接口参数，包含code、msg、data等等。默认只返回data，不包含code、msg。
      contentType: 'application/x-www-form-urlencoded',
    },
    data,
  });
}

// 获取微信公众号支付参数
/*
 @param code 微信授权码
 @param orderNo 订单号
 @param projectId 项目id
*/
export function getWxPubPayInfo(params = {}, opts = {}) {
  return request({
    url: `/shop/callback/weixin-pay/wxPub`,
    method: 'get',
    params,
    ...opts,
  });
}

// 获取微信扫码支付参数
export function getQrCodePayInfo(data = {}, opts = {}) {
  return request({
    url: `/shop/payment/saveOrder`,
    method: 'post',
    data,
    ...opts,
  });
}

//退出登录
export function deleteMapping(data = {}) {
  return request({
    url: `/auth/token/logout`,
    method: 'delete',
    headers: {
      notNeedToken: true, // 是否不需要token。默认需要。
      getAllResponse: true, // 获取接口参数，包含code、msg、data等等。默认只返回data，不包含code、msg。
      contentType: 'application/x-www-form-urlencoded',
    },
    data,
  });
}

/**
 * 新用户手机登录验证码发送
 */
export function getSmsBymini(params = {}, opts = {}) {
  return request({
    url: '/shop-mobile/user/getSmsBymini',
    method: 'get',
    params,
    ...opts,
  });
}


// 获取菜单
export function getAdminMenus(params = {}, opts = {}) {
  return request({
    url: '/shop/admin/menus',
    method: 'get',
    params,
    ...opts,
  });
}


// 获取用户权限
export function getUserFuncList(params = {}, opts = {}) {
  return request({
    url: '/shop/userInfo/func',
    method: 'get',
    params,
    ...opts,
  });
}

// 门店设置客服信息
export function setStoreCustomrt(data = {}, opts = {}) {
  return request({
    url: '/shop/admin/customer',
    method: 'post',
    data,
    ...opts,
  });
}

// 门店获取客服信息
export function getStoreCustomrt(params = {}, opts = {}) {
  return request({
    url: '/shop/admin/customer',
    method: 'get',
    params,
    ...opts,
  });
}

// 用户获取客服信息
export function getUserCustomrt(params = {}, opts = {}) {
  return request({
    url: '/shop/store/customer',
    method: 'get',
    params,
    ...opts,
  });
}

// 会员等级列表
export function getVipLevelPage(params = {}, opts = {}) {
  return request({
    url: '/mgmt/vipLevel/page',
    method: 'get',
    params,
    ...opts,
  });
}

export function uploadImage(file, subPath = '/store/Image') {
  const params = new FormData();
  params.append('file', file);
  return request({
    url: `/upload${ subPath }`,
    method: 'post',
    headers: { 'contentType': 'multipart/form-data', getAllResponse: true },
    data: params,
  });
}

export async function uploadImgByBase64ImgStr(Base64ImgStr, subPath = '/store/Image') {
  let blob = base64ToBlob(Base64ImgStr);
  const fileName = `${ Date.now() }.${ blob.type.replace('image/', '') }`;
  blob.lastModifiedDate = new Date();
  blob.name = fileName;
  let file = new File([blob], fileName, { type: blob.type });
  file = await compressor(file);
  return new Promise(resolve => {
    uploadImage(file, subPath).then(res => {
      const {
        status,
        data,
        statusText,
      } = res || {};
      if (status === 200 && data && data.url) {
        resolve(data.url);
      } else {
        const { msg } = data || {};
        showConfirmDialog({
          message: msg || statusText || '请求失败.',
          showCancelButton: false,
          confirmButtonText: '好的',
        }).then();
        resolve();
      }
    });
  });
}

export function sendVerificationCode(params = {}, opts = {}) {
  // return new Promise(resolve => setTimeout(() => resolve(true), 321))
  return request({
    url: '/shop/api/sms/sendVerificationCode',
    method: 'get',
    params,
    ...opts,
  });
}

// projectCode
export function getProjectInfoByCode(params = {}, opts = {}) {
  return request({
    url: '/shop/api/projectInfo/getByCode',
    method: 'get',
    params,
    ...opts,
  });
}

export function getWxpubInfo(params = {}, opts = {}) {
  return request({
    url: '/shop/api/projectInfo/getWxpubInfo',
    method: 'get',
    params,
    ...opts,
  });
}

// 根据storeCode获取店铺信息
export function getStoreInfoApi(params = {}, opts = {}) {
  return request({
    url: '/shop/api/storeInfo/getStoreInfo',
    method: 'get',
    params,
    ...opts,
  });
}



// 绑定用户手机号
export function bindUserPhone(data = {}, opts = {}) {
  return request({
    url: '/shop/userInfo/bindUserPhone',
    method: 'post',
    data,
    ...opts,
  });
}

// 获取图片base64字符串
export function getImgBase64(params = {}, opts = {}) {
  return request({
    url: '/shop/api/projectInfo/getImgBase64',
    method: 'get',
    params,
    ...opts,
  });
}
