import { defineStore } from 'pinia';
import { nextTick, ref } from 'vue';

export const ADMIN_PRINT = '/pages/print/index';
export const AGENCY_ORDER = '/action/order';
export const CASH_SETTING = '/pages/cashSetting/index';
export const DATA_CENTER = 'dataCenterPage';
export const ADMIN_SHAREHOLDER_DATA = '/pages/shareholderData/index';
export const ADMIN_STORE_DATA = '/pages/storeData/index';
export const ADMIN_SALES_STATISTICS = '/pages/goodsData/index';
export const ADMIN_ACCOUNT_FLOW = '/pages/storeflow/index';
export const ADMIN_FLOW_STATIS = '/pages/storeflow/statis/index';
export const ADMIN_GOODS_INDEX_CLASSIFY = '/package-admin/pages/adminGoodsIndexClassify/index';

export const PAGE_NAME = {
  ADMIN_LOCAL_GOODS_MANAGEMENT: '/pages/goodsManagement/index',
  ADMIN_ORDER_LIST: '/pages/adminOrder/adminOrderList/index',
  ADMIN_ORDER_RETURN_LIST: '/pages/myOrderReturnList/index',
  ADMIN_COUPON_LIST: '/pages/adminCoupon/adminCouponList/index',
  ADMIN_ROLE_MANAGEMENT: '/pages/permissionManagement/index',
  ADMIN_BRAND_GOODS: '/pages/goodsManagement/brandGoods/index',
  ADMIN_BANNER: '/pages/adminBanner/index',
  ADMIN_GOODS_RECOMMEND_LIST: '/pages/adminGoodsRecommend/adminGoodsRecommendList/index',
  ADMIN_FOR_HERE_GOODS_MANAGEMENT: '/pages/forhere/index',
  ADMIN_USER_MANAGEMENT_LIST: '/pages/adminUserManagement/userManagementList/index',
  ADMIN_SHAREHOLDER_SCHEME_LIST: '/pages/reward/reward',
  ADMIN_SHAREHOLDER_LIST: '/pages/adminShareholderManagement/shareholderManagementList/index',
  ADMIN_SHAREHOLDER_DIVIDEND_TOTAL_DATA: '/pages/shareholderDividendData/index',
  ADMIN_STORE_CONTRACT_RECORD: '/package-admin/pages/storeContractRecord/index',
  ADMIN_STORE_WITHDRAWAL: '/pages/storeWithdrawal/index',
  ADMIN_WITHDRAWAL_AUDIT: '/pages/Withdrawal/index',
  ADMIN_APPLY_FOR_INVOICE_LIST: '/pages/invoiceapplication/index',
  ADMIN_MY_CONTRACT: '/pages/myContract/index',
  ADMIN_REGISTRY_STORE: '/package-admin/pages/registryStore/index',
};

const allKeepAlivePageNames = () => [
  'homePage', 'myOrderListPage', 'forhereList', 'storePage', 'myPage',
  'homeGoodsPage',
  'paymentPage',
  'bankCardModify',
  'myCourseList',
  ...Object.keys(PAGE_NAME).map(k => PAGE_NAME[k]),
];

export const useKeepAliveStore = defineStore('keepAliveControl', () => {
  const keepAlivePageNameList = ref(allKeepAlivePageNames());

  async function reset(name = '') {
    if (!name) {
      keepAlivePageNameList.value = [];
      await nextTick();
      keepAlivePageNameList.value = allKeepAlivePageNames();
    } else {
      await destroy(name);
      add(name);
    }
  }

  function add(name = '') {
    if (name)
      keepAlivePageNameList.value = [...keepAlivePageNameList.value, name];
  }

  async function destroy(name = '') {
    const index = keepAlivePageNameList.value.findIndex(e => name && e === name);
    if (index > -1) {
      keepAlivePageNameList.value.splice(index, 1);
      await nextTick();
    }
  }

  return {
    keepAlivePageNameList,
    add, destroy, reset,
  };
});
