import { createRouter, createWebHashHistory } from 'vue-router';
import { GET_TOKEN } from '@/util/storage';
import {
  ADMIN_ACCOUNT_FLOW, ADMIN_FLOW_STATIS, ADMIN_GOODS_INDEX_CLASSIFY,
  ADMIN_PRINT, ADMIN_SALES_STATISTICS,
  ADMIN_SHAREHOLDER_DATA, ADMIN_STORE_DATA,
  AGENCY_ORDER,
  CASH_SETTING,
  DATA_CENTER,
  PAGE_NAME
} from '@/stores/keepAlive';
import { PAY_ROUTER_ARR } from '@/views/payPage/const';

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: '/',
      name: 'boot',
      component: () => import('@/views/boot.vue')
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/login.vue')
    },
    ...PAY_ROUTER_ARR,
    {
      path: '/home/home',
      name: 'homePage',
      component: () => import('@/views/home/home/homePage.vue'),
    },
    {
      path: '/home/order',
      name: 'homeOrder',
      component: () => import('@/views/home/order/myOrderListPage.vue'),
    },
    {
      path: '/home/forhereList',
      name: 'homeForhereList',
      component: () => import('@/views/home/forhereList/forhereList.vue'),
    },
    {
      path: '/home/store',
      name: 'homeStore',
      component: () => import('@/views/home/store/storePage.vue'),
    },
    {
      path: '/home/my',
      name: 'homeMy',
      component: () => import('@/views/home/my/myPage.vue'),
    },
    {
      path: '/goods',
      name: 'goods',
      // meta: { keepAlive: true },
      component: () => import('@/views/goods/homeGoodsPage.vue'),
    },
    {
      path: '/goods/comment',
      name: 'goodsComment',
      component: () => import('@/views/goods/comment.vue'),
    },
    {
      path: '/goodsDetail',
      name: 'goodsDetail',
      component: () => import('@/views/goodsDetail.vue'),
    },
    {
      path: '/cart',
      name: 'cart',
      component: () => import('@/views/cart.vue'),
    },
    {
      path: '/payment',
      name: 'payment',
      component: () => import('@/views/payment/paymentPage.vue')
    },
    {
      path: '/agencyOrder',
      name: AGENCY_ORDER,
      component: () => import('@/views/admin/agencyOrder/agencyOrder.vue'),
      children: [
        {
          path: '/agencyOrder/adminGoodsSelectPage',
          name: 'agencyOrderGoodsSelectPage',
          component: () => import('@/views/admin/adminGoodsSelectPage.vue')
        },
      ],
    },
    {
      path: '/paymentAddressSelect',
      name: 'paymentAddressSelect',
      component: () => import('@/views/payment/paymentAddressSelect.vue')
    },
    {
      path: '/myOrderDetail',
      name: 'myOrderDetail',
      component: () => import('@/views/myOrderDetail.vue')
    },
    {
      path: '/adminOrderShareDetail',
      name: 'adminOrderShareDetail',
      component: () => import('@/views/admin/adminOrderShareDetail.vue')
    },
    {
      path: '/adminApplyForInvoiceList',
      name: PAGE_NAME.ADMIN_APPLY_FOR_INVOICE_LIST,
      component: () => import('@/views/admin/adminApplyForInvoiceList.vue')
    },
    {
      path: '/adminStoreWithdrawal',
      name: PAGE_NAME.ADMIN_STORE_WITHDRAWAL,
      component: () => import('@/views/admin/adminStoreWithdrawal.vue')
    },
    {
      path: '/adminWithdrawalAudit',
      name: PAGE_NAME.ADMIN_WITHDRAWAL_AUDIT,
      component: () => import('@/views/admin/adminWithdrawalAudit.vue')
    },
    {
      path: '/adminShareholderData',
      name: ADMIN_SHAREHOLDER_DATA,
      component: () => import('@/views/admin/adminShareholderData.vue')
    },
    {
      path: '/adminStoreData',
      name: ADMIN_STORE_DATA,
      component: () => import('@/views/admin/adminStoreData.vue')
    },
    {
      path: '/adminSalesStatistics',
      name: ADMIN_SALES_STATISTICS,
      component: () => import('@/views/admin/adminSalesStatistics.vue')
    },
    {
      path: '/adminAccountFlow',
      name: ADMIN_ACCOUNT_FLOW,
      component: () => import('@/views/admin/adminAccountFlow.vue')
    },
    {
      path: '/adminFlowStatis',
      name: ADMIN_FLOW_STATIS,
      component: () => import('@/views/admin/adminFlowStatis.vue')
    },
    {
      path: '/adminGoodsIndexClassify',
      name: ADMIN_GOODS_INDEX_CLASSIFY,
      component: () => import('@/views/admin/adminGoodsIndexClassify.vue')
    },
    {
      path: '/adminFlowStatisToEnd',
      component: () => import('@/views/admin/adminFlowStatisToEnd.vue')
    },
    {
      path: '/adminFlowStatisDetail',
      component: () => import('@/views/admin/adminFlowStatisDetail.vue')
    },
    {
      path: '/applyForReturn',
      name: 'applyForReturn',
      component: () => import('@/views/applyForReturn.vue')
    },
    {
      path: '/myOrderReturnDetail',
      name: 'myOrderReturnDetail',
      component: () => import('@/views/myOrderReturnDetail.vue')
    },
    {
      path: '/myOrderReturnGoodsSend',
      name: 'myOrderReturnGoodsSend',
      component: () => import('@/views/myOrderReturnGoodsSend.vue'),
      children: [
        {
          path: '/myOrderReturnGoodsSend/expressList',
          name: 'returnGoodsSendExpressList',
          component: () => import('@/views/expressList.vue')
        },
      ]
    },
    {
      path: '/adminOrderList',
      name: PAGE_NAME.ADMIN_ORDER_LIST,
      component: () => import('@/views/admin/adminOrder/adminOrderList/index.vue')
    },
    {
      path: '/adminBanner',
      name: PAGE_NAME.ADMIN_BANNER,
      component: () => import('@/views/admin/adminBanner/adminBanner.vue'),
    },
    {
      path: '/adminBannerSelectPage',
      name: 'adminBannerSelectPage',
      component: () => import('@/views/admin/adminBanner/adminBannerSelectPage.vue'),
    },
    {
      path: '/adminGoodsRecommendList',
      name: PAGE_NAME.ADMIN_GOODS_RECOMMEND_LIST,
      component: () => import('@/views/admin/adminGoodsRecommend/adminGoodsRecommendList.vue'),
    },
    {
      path: '/adminGoodsRecommendSelect',
      name: 'adminGoodsRecommendSelect',
      component: () => import('@/views/admin/adminGoodsRecommend/adminGoodsRecommendSelect.vue'),
    },
    {
      path: '/adminForHereGoodsManagement',
      name: PAGE_NAME.ADMIN_FOR_HERE_GOODS_MANAGEMENT,
      component: () => import('@/views/admin/adminForHereGoodsManagement/adminForHereGoodsManagement.vue'),
    },
    {
      path: '/adminForHereGoodsSelectPage',
      component: () => import('@/views/admin/adminForHereGoodsManagement/adminForHereGoodsSelectPage.vue'),
    },
    {
      path: '/adminUserAndRoleManagement',
      name: PAGE_NAME.ADMIN_ROLE_MANAGEMENT,
      component: () => import('@/views/admin/adminRoleManagement/adminUserAndRoleManagement.vue'),
    },
    {
      path: '/adminUserModify',
      component: () => import('@/views/admin/adminRoleManagement/adminUser/adminUserModify.vue'),
    },
    {
      path: '/adminRoleModify',
      component: () => import('@/views/admin/adminRoleManagement/adminRole/adminRoleModify.vue'),
    },
    {
      path: '/adminPrint',
      name: ADMIN_PRINT,
      component: () => import('@/views/admin/adminPrint.vue'),
    },
    {
      path: '/cashSetting',
      name: CASH_SETTING,
      component: () => import('@/views/admin/cashSetting.vue'),
    },
    {
      path: '/manualDelivery',
      name: 'manualDelivery',
      component: () => import('@/views/admin/manualDelivery.vue'),
      children: [
        {
          path: '/manualDelivery/expressList',
          name: 'manualDeliveryExpressList',
          component: () => import('@/views/expressList.vue')
        },
      ]
    },
    {
      path: '/adminOrderDetail',
      name: 'adminOrderDetail',
      component: () => import('@/views/admin/adminOrder/adminOrderDetail.vue'),
    },
    {
      path: '/adminHandlingOrderReturn',
      name: 'adminHandlingOrderReturn',
      component: () => import('@/views/admin/adminOrder/adminHandlingOrderReturn.vue'),
      children: [
        {
          path: '/adminHandlingOrderReturn/orderReturnAddressSelect',
          name: 'orderReturnAddressSelect',
          component: () => import('@/views/admin/adminOrder/orderReturnAddressSelect.vue')
        },
        {
          path: '/adminHandlingOrderReturn/orderReturnAddressModify',
          name: 'orderReturnAddressModify',
          component: () => import('@/views/adminAddress/modifyAddress/index.vue')
        },
      ]
    },
    {
      path: '/adminCouponList',
      name: PAGE_NAME.ADMIN_COUPON_LIST,
      component: () => import('@/views/admin/adminCoupon/adminCouponList/index.vue')
    },
    {
      path: '/adminModifyCoupon',
      name: 'adminModifyCoupon',
      component: () => import('@/views/admin/adminCoupon/adminModifyCoupon.vue'),
      children: [
        {
          path: '/adminModifyCoupon/adminGoodsSelectPage',
          name: 'adminCouponGoodsSelectPage',
          component: () => import('@/views/admin/adminGoodsSelectPage.vue')
        },
      ]
    },
    {
      path: '/adminSendCoupon',
      name: 'adminSendCoupon',
      component: () => import('@/views/admin/adminCoupon/adminSendCoupon.vue'),
    },
    {
      path: '/couponProvideRecord',
      name: 'couponProvideRecord',
      component: () => import('@/views/admin/adminCoupon/couponProvideRecord.vue'),
    },
    {
      path: '/adminBrandGoods',
      name: PAGE_NAME.ADMIN_BRAND_GOODS,
      component: () => import('@/views/admin/adminBrandGoods/index.vue'),
    },
    {
      path: '/adminLocalGoodsManagement',
      name: PAGE_NAME.ADMIN_LOCAL_GOODS_MANAGEMENT,
      component: () => import('@/views/admin/adminLocalGoodsManagement/adminLocalGoodsManagement.vue'),
    },
    {
      path: '/adminLocalGoodsAuditAndModify',
      name: 'adminLocalGoodsAuditAndModify',
      component: () => import('@/views/admin/adminLocalGoodsManagement/adminLocalGoodsAuditAndModify.vue'),
      children: [
        {
          path: '/adminLocalGoodsAuditAndModify/QuillEditor',
          name: 'adminLocalGoodsAuditAndModifyQuillEditor',
          component: () => import('@/components/QuillEditor.vue')
        },
      ],
    },
    {
      path: '/adminShareholderSchemeList',
      name: PAGE_NAME.ADMIN_SHAREHOLDER_SCHEME_LIST,
      component: () => import('@/views/admin/shareholderScheme/adminShareholderSchemeList.vue'),
    },
    {
      path: '/adminShareholderSchemeModify',
      component: () => import('@/views/admin/shareholderScheme/adminShareholderSchemeModify.vue'),
      children: [
        {
          path: '/adminShareholderSchemeModify/adminShareholderSchemeGoodsSelectPage',
          name: 'adminShareholderSchemeGoodsSelectPage',
          component: () => import('@/views/admin/adminGoodsSelectPage.vue')
        },
      ],
    },
    {
      path: '/adminShareholderList',
      name: PAGE_NAME.ADMIN_SHAREHOLDER_LIST,
      component: () => import('@/views/admin/shareholderManagement/shareholderList.vue'),
    },
    {
      path: '/shareholderModify',
      component: () => import('@/views/admin/shareholderManagement/shareholderModify.vue'),
      children: [
        {
          path: '/shareholderModify/shareholdersSchemeSelectPage',
          component: () => import('@/views/admin/shareholderManagement/shareholdersSchemeSelectPage.vue')
        },
      ],
    },
    {
      path: '/shareholderDividendTotalData',
      name: PAGE_NAME.ADMIN_SHAREHOLDER_DIVIDEND_TOTAL_DATA,
      component: () => import('@/views/admin/shareholderDividendData/shareholderDividendTotalData.vue'),
    },
    {
      path: '/adminStoreContractRecord',
      name: PAGE_NAME.ADMIN_STORE_CONTRACT_RECORD,
      component: () => import('@/views/admin/adminStoreContract/adminStoreContractRecord.vue'),
    },
    {
      path: '/adminStoreContractDetail',
      component: () => import('@/views/admin/adminStoreContract/adminStoreContractDetail.vue'),
    },
    {
      path: '/goodsView',
      name: 'goodsView',
      component: () => import('@/views/admin/goodsView.vue'),
    },
    {
      path: '/adminStoreDetail',
      name: 'adminStoreDetail',
      component: () => import('@/views/admin/adminStoreDetail.vue'),
    },
    {
      path: '/adminPostageRules',
      name: '/pages/adminPostageRules/adminPostageRuleList/index',
      component: () => import('@/views/admin/adminPostageRules/adminPostageRules.vue'),
    },
    {
      path: '/scanOrderConfig',
      name: '/package-admin/pages/scanOrderConfig/index',
      component: () => import('@/views/admin/scanOrderConfig.vue'),
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('@/views/about.vue')
    },
    {
      path: '/myCoupon',
      name: 'myCoupon',
      component: () => import('@/views/myCoupon.vue')
    },
    {
      path: '/updateUserInfo',
      name: 'updateUserInfo',
      component: () => import('@/views/updateUserInfo.vue')
    },
    {
      path: '/bankCardList',
      name: 'bankCardList',
      component: () => import('@/views/bankCardList.vue')
    },
    {
      path: '/bankCardModify',
      name: 'bankCardModify',
      // meta: { keepAlive: true },
      component: () => import('@/views/bankCardModify.vue'),
    },
    {
      path: '/adaPayBankList',
      name: 'adaPayBankList',
      component: () => import('@/views/adaPayBankList.vue')
    },
    {
      path: '/addressList',
      name: 'addressList',
      component: () => import('@/views/address/addressList.vue')
    },
    {
      path: '/modifyAddress',
      name: 'modifyAddress',
      component: () => import('@/views/address/modifyAddress.vue')
    },
    {
      path: '/storeLive',
      name: 'storeLive',
      component: () => import('@/views/storeLive.vue')
    },

    {
      path: '/test',
      name: 'test',
      component: () => import('@/views/test/index.vue')

    },
    {
      path: '/searchPage',
      name: 'searchPage',
      component: () => import('@/views/searchPage.vue')
    },
    {
      path: '/orderForm',
      name: 'orderForm',
      component: () => import('@/views/orderForm.vue')
    },

    {
      path: '/storeCustomer',
      name: '/pages/storeCustomer/index',
      component: () => import('@/views/storeCustomer/index.vue')
    },
    {
      path: '/basicInfo',
      name: '/pages/basicInfo/index',
      component: () => import('@/views/basicInfo/index.vue')
    },
    {
      path: '/adminAddress/adminAddressList',
      name: '/pages/adminAddress/adminAddressList/index',
      component: () => import('@/views/adminAddress/adminAddressList/index.vue')
    },
    {
      path: '/adminAddress/modifyAddress',
      name: '/pages/adminAddress/modifyAddress/index',
      component: () => import('@/views/adminAddress/modifyAddress/index.vue')
    },
    {
      path: '/storeLiveManagement/storeLiveList',
      name: '/package-admin/pages/storeLiveManagement/storeLiveList/index',
      meta: { keepAlive: true },
      component: () => import('@/views/storeLiveManagement/storeLiveList/index.vue')
    },
    {
      path: '/storeLiveManagement/storeLiveModify',
      name: '/package-admin/pages/storeLiveManagement/storeLiveModify/index',
      component: () => import('@/views/storeLiveManagement/storeLiveModify/index.vue')
    },
    {
      path: '/storeDetail',
      name: 'storeDetail',
      component: () => import('@/views/storeDetail/index.vue'),
    },
    {
      path: '/evaluate/releaseEvaluate',
      name: 'releaseEvaluate',
      component: () => import('@/views/evaluate/releaseEvaluate/index.vue'),
    },

    {
      path: '/adminUserManagementList',
      name: PAGE_NAME.ADMIN_USER_MANAGEMENT_LIST,
      component: () => import('@/views/adminUserManagement/userManagementList/index.vue'),
    },
    {
      path: '/adminModifyUser',
      name: 'adminModifyUser',
      component: () => import('@/views/adminUserManagement/adminModifyUser/index.vue'),
    },
    {
      path: '/adminUserDetail',
      name: 'adminUserDetail',
      component: () => import('@/views/adminUserManagement/userDetail/index.vue'),
    },
    {
      path: '/shareholderDividendRecords',
      name: 'shareholderDividendRecords',
      component: () => import('@/views/admin/shareholderDividendRecords/index.vue'),
    },
    {
      path: '/dataCenter',
      name: DATA_CENTER,
      component: () => import('@/views/dataCenter/index.vue'),
    },
    {
      path: '/informationContent',
      name: 'informationContent',
      component: () => import('@/views/dataCenter/informationContent.vue'),
    },
    {
      path: '/userPointFlow',
      name: 'userPointFlow',
      component: () => import('@/views/userPointFlow/index.vue'),
    },
    {
      path: '/accountFlow/userAccountFlow',
      name: 'userAccountFlow',
      component: () => import('@/views/accountFlow/userAccountFlow/index.vue'),
    },
    {
      path: '/applyForPromoter',
      name: 'applyForPromoter',
      component: () => import('@/views/applyForPromoter/index.vue'),
    },
    {
      path: '/adminMyPromotion',
      name: 'adminMyPromotion',
      component: () => import('@/views/admin/myPromotion/index.vue'),
    },
    {
      path: '/adminWrittenContract',
      name: 'adminWrittenContract',
      component: () => import('@/views/admin/writtenContract/index.vue'),
    },
    {
      path: '/myOrderReturnList',
      name: PAGE_NAME.ADMIN_ORDER_RETURN_LIST,
      component: () => import('@/views/myOrderReturnList/index.vue'),
    },
    {
      path: '/myWithdrawal',
      name: 'myWithdrawal',
      component: () => import('@/views/myWithdrawal.vue'),
    },
    {
      path: '/myWithdrawalRecords',
      name: 'myWithdrawalRecords',
      component: () => import('@/views/myWithdrawalRecords.vue'),
    },
    {
      path: '/myContract',
      name: PAGE_NAME.ADMIN_MY_CONTRACT,
      component: () => import('@/views/myContract/index.vue'),
    },
    {
      path: '/adminRegistryStore',
      name: PAGE_NAME.ADMIN_REGISTRY_STORE,
      component: () => import('@/views/admin/registryStore/index.vue'),
    },
    {
      path: '/personInfo',
      name: 'personInfo',
      component: () => import('@/views/personInfo.vue')
    },
    {
      path: '/modifyPassword',
      name: 'modifyPassword',
      component: () => import('@/views/modifyPassword.vue')
    },
    {
      path: '/bindOffiaccountByPhone',
      name: 'bindOffiaccountByPhone',
      component: () => import('@/views/bindOffiaccountByPhone.vue')
    },
    {
      path: '/wxPubLoginSelect',
      name: 'wxPubLoginSelect',
      component: () => import('@/views/wxPubLoginSelect.vue')
    },
    {
      path: '/myCourseList',
      name: 'myCourseList',
      component: () => import('@/views/course/myCourseList.vue')
    },
    {
      path: '/myCourseDetail',
      name: 'myCourseDetail',
      component: () => import('@/views/course/myCourseDetail.vue')
    },
    {
      path: '/myMaterialList',
      name: 'myMaterialList',
      component: () => import('@/views/course/myMaterialList.vue')
    },
    {
      path: '/materialText',
      name: 'materialText',
      component: () => import('@/views/course/materialText.vue')
    },
    {
      path: '/pay',
      name: 'qrCodePay',
      component: () => import('@/views/payPage/qrCodePay.vue')
    },
  ]
});

router.beforeEach((to, from, next) => {
  if (!['/login', '/bindOffiaccountByPhone', '/wxPubLoginSelect', '/pay', '/'].includes(to.path)) {
    if (!GET_TOKEN()) {
      next({ name: 'boot' });
    } else {
      next();
    }
  } else
    next();
});

export default router;
