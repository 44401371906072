import {
  listToMap
} from './utils';

// 订单类型
// forhere.自提
// package.打包带走
// online.线上配送
// pick.门店自提
// express.快递配送
// delivery.同城配送
// help.代下单

export const EXPRESS = 'express'; // 快递配送
export const DELIVERY = 'delivery'; // 同城配送
export const FORHERE = 'forhere'; // 自提
export const PACKAGE = 'package'; // 打包带走
export const ONLINE = 'online'; // 线上配送
export const PICK = 'pick'; // 门店自提
export const HELP = 'help'; // 代下单
export const SCAN_CODE = 'scanCode'; // 扫码支付

export const ORDER_TYPE_DIC = {
  FORHERE,
  PACKAGE,
  ONLINE,
  PICK,
  EXPRESS,
  DELIVERY,
  HELP,
  SCAN_CODE,
};

export const ORDER_TYPE_LIST = [
  {
    id: EXPRESS,
    name: '快递配送'
  },
  {
    id: DELIVERY,
    name: '同城配送'
  },
  {
    id: FORHERE,
    name: '自提'
  },
  {
    id: PACKAGE,
    name: '打包带走'
  },
  {
    id: ONLINE,
    name: '配送到家'
  },
  {
    id: PICK,
    name: '门店自提'
  },
  {
    id: HELP,
    name: '代下单'
  },
  {
    id: SCAN_CODE,
    name: '扫码支付'
  },
];

export const ORDER_TYPE = listToMap(ORDER_TYPE_LIST);
